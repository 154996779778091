import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const ContatoResposta = () => (
  <Layout>
    <SEO />
    <section className="entre-em-contato top-espaco">
      <div className="container">
        <h2>ENTRE EM CONTATO</h2>
        <div class="alert alert-success">
          E-mail enviado com sucesso!
        </div>
      </div>
    </section>
  </Layout>
)

export default ContatoResposta